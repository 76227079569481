import { MESSAGES, RESERVED_PROPS } from "config/default.config";
import {
  FILE_MIME_TYPES,
  FILE_TYPES,
  IFilePreview,
  NUMERIC_TYPES,
} from "modules/property-console/property-console.interface";
import { IInstanceItemDetails, IInstanceItemProperty } from "store/asset-instance/asset-instance.interface";
import downloadIcon from "../../../assets/images/seqrops-download-icon.svg";
import deleteIcon from "../../../assets/images/seqrops_delete_icon.svg";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import DeleteConfirmationDialog from "components/dialogs/delete-confirmation.dialog";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setDeleteClicked, setHasChange, setIsDirty, setStaticSwitch, setSwitchItem } from "store/seqr-ops/seqr-ops.slice";
import { useAppSelector } from "store";
import ConfirmationDialog from "components/dialogs/confirmation.dialog";


const StaticPropertyForm = ({
  properties,
  instance,
  register,
  errors,
  setValue,
  clearErrors,
  filePreview,
  handleFileChange,
}: {
  properties: IInstanceItemProperty[];
  instance: IInstanceItemDetails;
  register: any;
  setValue: any;
  errors: any;
  clearErrors: any;
  filePreview: IFilePreview;

  handleFileChange: (filePreview: IFilePreview) => void;
}) => {
  const deleteConfirmationPopup = DeleteConfirmationDialog();
  const [dateMap, setdateMap] = useState<any>({});

  const staticSwitch = useAppSelector((state) => state.seqrOps.staticSwitch);

  const errorWrap = (errorMsg: string | undefined) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  const dispatch = useDispatch()
  const handleFormChange = () => {
    dispatch(setHasChange(rid))
    dispatch(setIsDirty(true))
  }
  //handling date-time change
  const handleDatePickerChange = (dateTime: Date, propertyName: string) => {
    setValue('properties.' + propertyName, dateTime);
    handleAdd(propertyName, dateTime)
  }


  const handleAdd = (key: string, value: Date) => {
    setdateMap((prevDateMap: any) => ({
      ...prevDateMap,
      [key]: value
    }));
  }
  const isDirty = useAppSelector((state) => state.seqrOps.isDirty)
  const hasChange = useAppSelector((state) => state.seqrOps.hasChange)
  const confirmationPopup = ConfirmationDialog()
  const rid = instance.rid

  useEffect(() => {
    
    // if (isDirty ) {
    //   confirmationPopup.onOpenDialog({
    //     handleClose() {
    //       confirmationPopup.closeDialog()
    //       dispatch(setIsDirty(false))
    //       dispatch(setHasChange(instance.rid))
    //     },
    //   })
    // }

    for (const element of properties) {
      let property = element;
      if (property?.custom?.schema?.defaultValue === "TODAY") {
        const dateTime = new Date(instance?.instance[property.name])

        let date = moment.utc(instance?.instance[property.name]).local().toDate();

        handleAdd(property.name, date)
        setValue('properties.' + property.name, date);
      }
    }
  }, [properties, isDirty, hasChange]);

  useEffect(() => {
    console.log("in popup");
    
    if (isDirty && staticSwitch) {
      confirmationPopup.onOpenDialog({
        handleClose() {
          confirmationPopup.closeDialog();
          
          dispatch(setStaticSwitch(false));
          dispatch(setIsDirty(false));
          dispatch(setSwitchItem("DYNAMIC"));
        },
      });
    }
  }, [staticSwitch,isDirty]);



  const renderString = (property: IInstanceItemProperty) => {
    if (
      !(
        property.type === "STRING" &&
        property.custom.meta == null &&
        !FILE_TYPES.includes(property.custom.schema.type)
      )
    ) {
      return null;
    }
    return (
      <>
        <div className="seqrops-property-fields">
          <label className="form-label" htmlFor={property.name}>
            {property.name}
          </label>
          <input
            className="form-control"
            type="text"
            id={property.name}
            {...register("properties." + property.name, {})}
            onChange={() => {
              clearErrors()
              handleFormChange()
            }}
          />
          {errorWrap(errors?.properties?.[property.name]?.message)}
        </div>
      </>
    );
  };

  const renderDateTimePicker = (property: IInstanceItemProperty) => {

    if (
      !(
        property.type === "DATETIME" &&
        property.custom.meta == null &&
        !FILE_TYPES.includes(property.custom.schema.type) &&
        property?.custom?.schema?.defaultValue === "TODAY"
      )
    ) {
      return null;
    }
    return (
      <>
        <div className="seqrops-property-fields">
          <label className="form-label" htmlFor="DateTimePicker">
            {property.name}
          </label>
          <div >
            <Calendar style={{ width: "100%" }} value={dateMap[property.name]} onChange={(e: any) => {
              handleDatePickerChange(e.value, property.name)
              handleFormChange()
            }}
              dateFormat="dd/mm/yy" showTime showSeconds hourFormat="12" />
          </div>
        </div>
      </>
    );
  };


  const renderBoolean = (property: IInstanceItemProperty) => {
    if (
      !(
        property.type === "BOOLEAN" &&
        property.name !== RESERVED_PROPS.RESERVED_PROPERTY_DELETED &&
        property.custom.meta == null &&
        !FILE_TYPES.includes(property.custom.schema.type)
      )
    ) {
      return null;
    }
    return (
      <>
        <div className="seqrops-property-fields seqrops-property-checkbox">

          <input
            type="checkbox"
            className="round-checkbox me-2"
            id="required"
            {...register("properties." + property.name, {})}
            onChange={(e: any) => {
              handleFormChange()
            }}
          />
          <label className="form-label" htmlFor={property.name}>
            {property.name}
          </label>
          {errorWrap(errors?.properties?.[property.name]?.message)}
        </div>
      </>
    );
  }


  const renderNumber = (property: IInstanceItemProperty) => {
    if (!NUMERIC_TYPES.includes(property.type)) {
      return null;
    }
    return (
      <>
        <div className="seqrops-property-fields">
          <label className="form-label" htmlFor={property.name}>
            {property.name}
          </label>
          <input
            className="form-control"
            type="number"
            id={property.name}
            {...register("properties." + property.name, {
              pattern: {
                value: /^[^\s]+(\s+[^\s]+)*$/,
                message: MESSAGES.CONVENTION_NOT_MATCH,
              },
            })}
            onChange={(e: any) => {
              handleFormChange()
            }}
          />
          {errorWrap(errors?.properties?.[property.name]?.message)}
        </div>
      </>
    );
  };

  const renderAttachment = (property: IInstanceItemProperty) => {
    if (!FILE_TYPES.includes(property.custom.schema.type)) {
      return null;
    }
    return (
      <>
        {!filePreview[property.name]?.isDeleted &&
          filePreview[property.name]?.fileURL !== "" ? (
          <>
            <div className="seqrops-file-download-title-section d-flex justify-content-between mt-2">
              <div>
                <h6>{property.name}</h6>
              </div>
              <div className="d-flex seqrops-file-download-section align-items-center">
                <Tooltip title="Download">
                  <a
                    href={filePreview[property.name]?.fileURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={downloadIcon} alt="download-icon" />
                  </a>
                </Tooltip>
                <Tooltip title="Delete">
                  <img
                    src={deleteIcon}
                    alt="delete-icon"
                    className="img-fluid"
                    onClick={() => {
                      deleteConfirmationPopup.onOpenDialog({
                        title: "Delete Confirmation",
                        message: "Are you sure want to delete the file?",
                        cancelBtn: "No",
                        successBtn: "Yes",
                        handleClose: async () => {
                          handleFileChange({
                            ...filePreview,
                            [property.name]: {
                              ...filePreview[property.name],
                              isDeleted: true,
                              isChanged: true,
                            },
                          });
                          deleteConfirmationPopup.closeDialog();
                        },
                      });
                      handleFormChange()
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </>
        ) : (
          <>
            <>
              <div className="seqrops-property-fields mt-3 seqrops-property-fields-file">
                <label className="form-label" htmlFor={property.name}>
                  {property.name}
                </label>
                <input
                className="form-control"
                  type="file"
                  id={property.name}
                  {...register("properties." + property.name)}
                  accept={FILE_MIME_TYPES[property.custom.schema.type].join(
                    ", "
                  )}
                  onChange={(e: any) => {
                    handleFormChange()
                    handleFileChange({
                      ...filePreview,
                      [property.name]: {
                        ...filePreview[property.name],
                        isChanged: true,
                        file: {
                          ...filePreview[property.name].file,
                          file: e.target.files,
                        },
                      },
                    });
                  }}
                />
                {errorWrap(errors?.properties?.[property.name]?.message)}
              </div>
            </>
          </>
        )}
      </>
    );
  };
  return (
    <>
      {properties.map((property: IInstanceItemProperty) => {
        return (
          <div key={property.id}>
            {renderString(property)}
            {renderNumber(property)}
            {renderAttachment(property)}
            {renderBoolean(property)}
            {renderDateTimePicker(property)}
          </div>
        );
      })}
    </>
  );
};

export default StaticPropertyForm;