import { AppThunk } from "store";
import { KEYCLOAK_CLIENTS, KEYCLOAK_GRANT_TYPES, MESSAGES } from "../../config/default.config";
import { toasterUtils } from "../../utils/toaster.utils";
import { ILogoutTokenPayload } from "./asset-keycloak.interface";
import { assetKeycloakService } from "./asset-keycloak.service";
import { TokenServices } from "./token-services";
import { encryptUtils } from "utils/encryption.utils";
import { cookieUtils } from "utils/cookie.utils";
import { interceptiorUtils } from "utils/interceptor.utils";

export const validateToken = async (sessionId: string, setReady: any) => {
    // let encryptedSessionId = encryptUtils.encryptAuthKey(sessionId);
    const params = new URLSearchParams();
    params.append('session_id', encryptUtils.encryptAuthKey(sessionId));

    try {
      const response = await assetKeycloakService.validateToken(params);
      console.log(response);
      if (response?.data?.active) {
        let authToken = response?.data?.access_token
        let refreshToken = response?.data?.refresh_token
        let sessionId = response?.data?.session_state

      interceptiorUtils.initialize();
      TokenServices.processToken(authToken, refreshToken, sessionId, setReady);
      return true;
      }else if (response?.data?.active === false) {

        const refreshTokenReqParams = new URLSearchParams();
        params.append('username', "");
        params.append('password', "");
        params.append('client_id', KEYCLOAK_CLIENTS.ASSET_SUITE);
        params.append('client_secret', KEYCLOAK_CLIENTS.ASSET_SUITE);
        params.append('grant_type', KEYCLOAK_GRANT_TYPES.REFRESH_TOKENS);
        params.append('refresh_token', response?.data?.refresh_token);
        params.append('session_id', sessionId);

      const getTokenResponse = await assetKeycloakService.getLoginToken(refreshTokenReqParams);
      if(getTokenResponse?.data?.active === true){
        console.log("refreshTokenGot ::: ", response);
        let authToken = response?.data?.access_token
        let refreshToken = response?.data?.refresh_token
        let sessionId = response?.data?.session_state

        TokenServices.processToken(authToken, refreshToken, sessionId, setReady);
        return true;
      }else if (!getTokenResponse?.status){
        return null;
        }
      return null;
      }
    } catch (err) {
      return null;
    }
};

// const getLoginToken = (ILoginCredentials : ILoginCredentials) : AppThunk => {
//   return async (dispatch, getState) => {

//   const params = new URLSearchParams();
//   params.append('username', ILoginCredentials.username)
//   params.append('password', ILoginCredentials.password)
//   params.append('client_id', KEYCLOAK_CLIENTS.ASSET_SUITE);
//   params.append('client_secret', KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE)
//   params.append('grant_type', KEYCLOAK_GRANT_TYPES.PASSWORD);
//   params.append('refresh_token', "");
//   params.append('session_id', "");

//   try {
//     const response = await assetKeycloakService.getLoginToken(params);
//     if (response.status === 200 || response.status === 201 ) {
//       let cookieName = COOKIE_DETAILS.COOKIE_NAME;
//       let domainName = COOKIE_DETAILS.COOKIE_DOMAIN;
//       let sessionId  = response?.data?.session_state

//       cookieUtils.setCookie(cookieName, encryptUtils.encryptURL(sessionId), { domain: domainName });
//       var token = response?.data?.access_token; 
//       var refresh_token = response?.data?.refresh_token;

//       const ITokenDetails : ITokenDetails = {
//         auth_token : token,
//         refresh_token : refresh_token,
//         client_id : KEYCLOAK_CLIENTS.ASSET_SUITE,
//         client_secret : KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
//         username : ILoginCredentials.username,
//         password : ILoginCredentials.password,
//         session_id : sessionId,
//       }

//       //interceptors
//       if(response?.data?.session_state != null && response?.data?.session_state !== undefined){
//         interceptiorUtils.initialize();
//       }else {
//       }

//       let IValidateTokenPayload: IValidateTokenPayload = {
//         token : token,
//         username : ILoginCredentials.username,
//         password : ILoginCredentials.password, 
//         client_id : KEYCLOAK_CLIENTS.ASSET_SUITE,
//         client_secret : KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
//         grant_type: KEYCLOAK_GRANT_TYPES.PASSWORD
//       }

//       dispatch(setTokens(ITokenDetails));
//       // await TokenServices.processToken(IValidateTokenPayload, ITokenDetails);
//     } else {
//       // toasterUtils.showError(MESSAGES.INVALID_LOGIN_CREDENTIALS);
//     }
//   } catch (err) {
//     return null;
//   }
// }};


const logoutToken = async (ILogoutTokenPayload: ILogoutTokenPayload, setSuccess: (state: boolean) => void) => {

  const params = new URLSearchParams();
  params.append('refresh_token', ILogoutTokenPayload.refresh_token);
  params.append('session_id', ILogoutTokenPayload.session_id);

  try {
    const response = await assetKeycloakService.logoutToken(params);
    if (response.status) {
      let cookieName = `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`;
      cookieUtils.deleteCookie(cookieName);
      toasterUtils.showSuccess(MESSAGES.LOGGED_OUT);
      setSuccess(true);
      toasterUtils.showSuccess(MESSAGES.LOGGED_OUT);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};


export const assetKeycloakActions = {
  validateToken,
  logoutToken
};