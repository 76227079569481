import { useNavigate } from "react-router-dom";
import viewIcon from "../../assets/images/model_icon.svg";
import { encryptUtils } from "../../utils/encryption.utils";
import "./model-list-item.scss";
import seqropsIsEquipmentIcon from "assets/images/IsEquipment_icon.svg";
function ModelListItem(props: any) {
  const navigate = useNavigate();
  console.log("props ::: ", props);
  return (
    <>
      <div className="seqrops-model-list-item-wrapper">
        <div className="container-fluid p-0">
          <div className="seqrops-model-list-item-container d-flex justify-content-between align-items-center">
            <span
              className="d-flex w-100 text-left modelBox"
              onClick={() => {
                const data = { modelName: props.modelName };
                const details = encryptUtils.encryptURL(data);
                navigate("/model-instance/" + details);
              }}
            >
              <div className="d-flex modelIcon">
                {props.modelIcon ? (
                  <img src={props.modelIcon} alt="no image" />
                ) : (
                  // <img src={viewIcon} alt="seqrops-viewIcon" />
                  <img src={viewIcon} alt="seqrops-viewIcon" />
                )}
              </div>
              <div className="seqrops-modelItem-desc-wrapper px-2 mr-4 z-index-9">
                <span className="d-flex seqrops-modelItem ms-2">
                  <label htmlFor="">{props.modelName}</label>
                </span>
                <span className="seqrops-modelDescription ms-2">
                  {props.modelDescription}
                </span>
                {/* <span className="seqrops-modelInstanceCount d-flex ms-2 ">
                <label htmlFor=""> Instance Count</label>
                  <label htmlFor="" className="seqrops-modelInstanceCountLabel"> {props.instanceCount}</label>
                </span> */}
              </div>

              {/* <div className="seqrops-modelDescription ms-2">
                <span>{props.modelDescription}</span>
              </div> */}
              <div className="seqrops-modelIsEquipment ms-2 ">
                {props.modelIsEquipment === true ? (
                  <span className="list-item-instance-count">
                    {props.instanceCount}
                  </span>
                ) : // <img src={seqropsIsEquipmentIcon} alt="seqrops-viewIcon" />
                null}
              </div>
            </span>

            {/* <div className="seqrops-model-options-section d-flex">
              <button
                onClick={() => {
                  props.modelClickTrigger(props.modelName);
                }}
              >
                <img src={addIcon} alt="" />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ModelListItem;
