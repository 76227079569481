import "./dynamic-property.scss";
import { IInstanceItemProperty } from "store/asset-instance/asset-instance.interface";
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { Panel } from "primereact/panel";
import {
  IDeviceDetail,
  IDynamicProperty,
} from "modules/property-console/property-console.interface";
import { assetInstanceActions } from "store/asset-instance/asset-instance.action";
import { log } from "console";
import axios from "axios";
import { ASSET_MANAGEMENT_API } from "config/default.config";
import { useDispatch, useSelector } from "react-redux";
import { assetInstancePropertiesActions } from "store/instance-properties/instance-properties.action";

const DynamicPropertyForm = ({
  instanceName,
  properties,
  register,
  errors,
  clearErrors,
  setValue,
  getValues,
}: {
  instanceName: string;
  properties: IInstanceItemProperty[];
  register: any;
  errors: any;
  clearErrors: any;
  setValue: any;
  getValues: any;
}) => {
  const devices = useAppSelector((state) => state.instanceProperties);
  const [values, setValues] = useState<any[]>([]);
  const [deviceDetail, setDeviceDetail] = useState<IDeviceDetail[]>([]);
  useEffect(() => {
    console.log("properties :::::: ", properties);
  });
  const dispatch = useAppDispatch();
  const handleProtocolChange = (
    data: any,
    property: IInstanceItemProperty,
    index: number
  ) => {
    if (data === "ModbusTCP") {
      let IDeviceDetail: IDeviceDetail = {
        isModbusTCP: true,
        propertyId: property.id,
      };
      setDeviceDetail([...deviceDetail, IDeviceDetail]);
    } else {
      deviceDetail.forEach((detail: IDeviceDetail) => {
        if (detail.propertyId === property.id) {
          detail.isModbusTCP = false;
        }
        setValue(`dynamicProperties.${index}.ipAddress`, "");
        setValue(`dynamicProperties.${index}.port`, 0);
      });
    }
  };
  useEffect(() => {
    const formValues = getValues("dynamicProperties");
    const _values = [];

    for (let value of formValues) {
      const device = devices.dynamicProperties.filter(
        (x) => x.id === value.deviceId
      )?.[0];
      _values.push({
        ...value,
        device,
      });
    }
    setValues(_values);

    //setting modbusTCP props
    let tempDevices: IDeviceDetail[] = [];
    _values.forEach((property: any) => {
      if (
        String(property.port) !== "" &&
        String(property.port) !== "0" &&
        property.port !== 0
      ) {
        let deviceDetail: IDeviceDetail = {
          isModbusTCP: true,
          propertyId: property.propertyId,
        };
        tempDevices.push(deviceDetail);
      }
    });
    setDeviceDetail(tempDevices);
  }, [devices]);

  const errorWrap = (errorMsg: string | undefined) => {
    if (!errorMsg) return null;
    return (
      <p className="error-message-section justify-content-start d-flex">
        {errorMsg}
      </p>
    );
  };

  // Storing Unit data in store

  useEffect(() => {
    dispatch(assetInstanceActions.fetchDynamicProperties());
  }, []);

  const dynamicUnitProperties = useSelector(
    (state: any) => state.assetInstances.dynamicUnitProperties
  );
  console.log("Dynamic Unit Properties:", dynamicUnitProperties);

  // Convert the dynamicUnitProperties array into a key-value pair object

  const unitPropertiesMap = dynamicUnitProperties.reduce(
    (acc: any, property: any) => {
      acc[property.id] = property.name;
      return acc;
    },
    {}
  );

  // const renderDevice = (index: number, property: IInstanceItemProperty) => {
  //   if (property.custom.schema !== null) {
  //     return null;
  //   }
  //   return (
  //     <>
  //       <div className="seqrops-property-fields">
  //         <label className="form-label" htmlFor="Device">
  //           Device
  //         </label>
  //         <Autocomplete
  //           id="seqrops-relation-selecting-dropdown"
  //           {...register(`dynamicProperties.${index}.deviceId`, {})}
  //           defaultValue={values[index]?.device || null}
  //           options={devices.dynamicProperties}
  //           onChange={(e, data: any) => {
  //             setValue(`dynamicProperties.${index}.device`, data || undefined);
  //             setValue(
  //               `dynamicProperties.${index}.deviceId`,
  //               data?.id || undefined
  //             );

  //             const _values = [...values];
  //             if (!data?.id) {
  //               _values[index] = {
  //                 ...values[index],
  //                 deviceId: undefined,
  //                 protocol: "",
  //                 ipAddress: "",
  //               };
  //               setValue(`dynamicProperties.${index}.protocol`, "");
  //               setValue(`dynamicProperties.${index}.ipAddress`, "");
  //             } else {
  //               _values[index] = {
  //                 ...values[index],
  //                 device: data,
  //                 deviceId: data?.id || undefined,
  //               };
  //             }
  //             setValues([..._values]);
  //             clearErrors(`dynamicProperties.${index}.deviceId`);
  //           }}
  //           getOptionLabel={(option: any) => option.name}
  //           renderInput={(params: any) => {
  //             return <TextField {...params} key={params.id} label="" />;
  //           }}
  //         />
  //         {errorWrap(errors?.dynamicProperties?.[index]?.deviceId?.message)}
  //       </div>
  //     </>
  //   );
  // };
  // const renderProtocol = (index: number, property: IInstanceItemProperty) => {
  //   if (property.custom.schema !== null) {
  //     return null;
  //   }
  //   return (
  //     <>
  //       <div className="seqrops-property-fields">
  //         <label className="form-label" htmlFor="protocol">
  //           Protocol
  //         </label>
  //         <Autocomplete
  //           id="seqrops-relation-selecting-dropdown"
  //           disableClearable
  //           {...register(`dynamicProperties.${index}.protocol`, {})}
  //           options={devices.protocols}
  //           value={values[index]?.protocol || null}
  //           onChange={(e, data: any) => {
  //             handleProtocolChange(data, property, index);
  //             setValue(
  //               `dynamicProperties.${index}.protocol`,
  //               data || undefined
  //             );
  //             clearErrors(`dynamicProperties.${index}.protocol`);
  //             values[index].protocol = data || "";
  //             setValues([...values]);
  //           }}
  //           renderInput={(params: any) => {
  //             return <TextField {...params} key={params} label="" />;
  //           }}
  //         />
  //         <>
  //           {errorWrap(errors?.dynamicProperties?.[index]?.protocol?.message)}
  //         </>
  //       </div>
  //     </>
  //   );
  // };

  // const renderIPAddress = (index: number, property: IInstanceItemProperty) => {
  //   if (property.custom.schema !== null) {
  //     return null;
  //   }
  //   return (
  //     <>
  //       <div className="seqrops-property-fields">
  //         <label className="form-label" htmlFor="ipAddress">
  //           Ip address
  //         </label>
  //         <div className="div">
  //           <input
  //             className="form-control"
  //             type="text" readOnly
  //             name="ipAddress"
  //             {...register(`dynamicProperties.${index}.ipAddress`, {})}
  //           />
  //           {errorWrap(errors?.dynamicProperties?.[index]?.ipAddress?.message)}
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  // const renderPort = (index: number, property: IInstanceItemProperty) => {
  //   if (property.custom.schema !== null) {
  //     return null;
  //   }
  //   return (
  //     <>
  //       <div className="seqrops-property-fields">
  //         <label className="form-label" htmlFor="ipAddress">
  //           Port
  //         </label>
  //         <div className="div">
  //           <input
  //             className="form-control"
  //             type="number"
  //             name="port"
  //             {...register(`dynamicProperties.${index}.port`, {})}
  //           />
  //           {errorWrap(errors?.dynamicProperties?.[index]?.port?.message)}
  //         </div>
  //       </div>
  //     </>
  //   );
  // };
  return (
    <>
      {properties.map((property: IInstanceItemProperty, index: number) => {
        console.log("deviceDetail : ", deviceDetail);
        console.log("propertymmm:::::::::", property);
        // Access the name from the unitPropertiesMap object using the id
        const unitId = property?.custom?.meta?.unitId;
        const unitName = unitId ? unitPropertiesMap[unitId] : "";

        return (
          <Panel
            header={property.name}
            toggleable
            className="mb-4"
            key={property.id}
          >
            <div className="listDynamicData">
              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Name </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.name}
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.description}
                  />
                </div>
              </div>

              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Control Mode </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.controlMode}
                  />
                </div>
                <div className="form-group">
                  <label>TagMode </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.tagMode}
                  />
                </div>
              </div>

              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Type</label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.type}
                  />
                </div>
                <div className="form-group">
                  <label>Data Type </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.dataType}
                  />
                </div>
              </div>
              {(property?.custom?.meta?.type === "ANALOG" ||
                property?.custom?.meta?.type === "COMPUTED") && (
                <div className="dynmicList d-flex">
                  <div className="form-group">
                    <label>Min Value </label>
                    <input
                      type="text"
                      readOnly
                      value={property?.custom?.meta?.minValue}
                    />
                  </div>
                  <div className="form-group">
                    {" "}
                    <label>Max Value </label>
                    <input
                      type="text"
                      readOnly
                      value={property?.custom?.meta?.maxValue}
                    />
                  </div>
                </div>
              )}
              {(property?.custom?.meta?.type === "ANALOG" ||
                property?.custom?.meta?.type === "COMPUTED") && (
                <div className="dynmicList d-flex">
                  <div className="form-group">
                    <label>Unit</label>
                    <input type="text" readOnly value={unitName} />
                  </div>
                  <div className="form-group">
                    <label>Conversion Factor Formula </label>
                    <input
                      type="text"
                      readOnly
                      value={property?.custom?.meta?.conversionFactorFormula}
                    />
                  </div>
                </div>
              )}
            </div>
            {property?.custom?.meta?.type === "COMPUTED" && (
              <div className="dynmicList d-flex">
                <div className="form-group">
                  <label>Computed Formula </label>
                  <input
                    type="text"
                    readOnly
                    value={property?.custom?.meta?.computedFormula}
                  />
                </div>
              </div>
            )}

            <div className="seqrops-alarm-form-wrapper mt-4 mb-3">
              <div className="">
                <div className="seqrops-basic-form-head-section">
                  <p>Alarm</p>
                </div>

                {property?.custom?.meta?.type === "DIGITAL" && (
                  <div className="seqrops-alarm-form-section">
                    <div className="row col-md-12">
                      {/* <div>
                      <label className="form-label" htmlFor="normal">
                        Normal
                      </label>

                    </div> */}

                      <div className="col-md-12">
                        <label className="form-label" htmlFor="normalMessage">
                          Normal Message
                        </label>
                        <input
                          className="form-control"
                          value={property?.custom?.meta?.alarms?.[1].message}
                          readOnly
                        />
                      </div>

                      <div className="col-md-12">
                        <label className="form-label" htmlFor="abNormalMessage">
                          Abnormal Message
                        </label>
                        <input
                          className="form-control"
                          value={property?.custom?.meta?.alarms?.[0].message}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )}
                {property?.custom?.meta?.type !== "DIGITAL" &&
                  property?.custom?.meta?.type !== "" && (
                    <div className="seqrops-alarm-form-section">
                      {property?.custom?.meta?.alarms.map((alarm, index) => (
                        <div key={index}>
                          <div className="row col-md-12 mt-3">
                            <div className="seqrops-alarm-item d-flex justify">
                              <span className="mt-2 mb-2">
                                <h5>{alarm.type}</h5>
                              </span>
                            </div>
                            <>
                              <div className="alarm-value d-flex flex-column">
                                <div className="" id={`error_HIHI_${index}`}>
                                  <label
                                    className="form-label"
                                    htmlFor={`value_${index}`}
                                  >
                                    Value
                                  </label>
                                  <input
                                    className="me-2 form-control"
                                    type="number"
                                    value={alarm.value}
                                    readOnly
                                  />
                                </div>
                                <div className="">
                                  <label
                                    className="form-label"
                                    htmlFor={`message_${index}`}
                                  >
                                    Message
                                  </label>
                                  <input
                                    className="me-2 form-control"
                                    type="text"
                                    value={alarm.message}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>

            <div>
              {/* {renderDevice(index, property)}
              {renderProtocol(index, property)} */}
              {deviceDetail.map((detail: IDeviceDetail) => {
                if (detail.propertyId === property.id && detail.isModbusTCP) {
                  return (
                    <>
                      {/* {renderIPAddress(index, property)}
                      {renderPort(index, property)} */}
                    </>
                  );
                }
              })}
            </div>
          </Panel>
        );
      })}

      {properties.length === 0 ? (
        <p className="no-dynamic-property">
          No dynamic property for {instanceName}
        </p>
      ) : null}
    </>
  );
};

export default DynamicPropertyForm;
